import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import styled from 'styled-components'

import Navigation from 'components/layout/Navigation'

import Layout from 'components/layout/layout'
import SEO from 'components/shared/SEO'

import LazyImage from 'components/shared/lazyImage'
import { Text } from 'components/shared/typography'

const PageMain = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: ${({ theme }) => theme.navSize.mobile};

  ${({ theme }) => theme.media.lg.min} {
    max-width: 2160px;
    margin-top: ${({ theme }) => theme.navSize.desktop};
    margin-inline: auto;
    box-sizing: border-box;
    padding: 0 3rem;
  }

  ${({ theme }) => theme.media.xxxl.min} {
    padding: 0 10rem;
  }
`

const TilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.5rem;
  margin: 4rem 0;

  transition: 250ms ease-in-out;

  ${({ theme }) => theme.media.lg.min} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin: 8rem 0;
  }

  ${({ theme }) => theme.media.xxl.min} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
  }
`

const TileWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 370px;
  margin-bottom: 4.5rem;

  ${({ theme }) => theme.media.lg.min} {
    align-self: flex-start;
    margin-bottom: 0;
  }
`

const ImgWrapper = styled.div`
  position: relative;
  height: 360px;

  & > div {
    height: 100%;
  }
`

const InnerLabel = styled(Text)`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const SlideInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 1.5rem;
  opacity: 0.7;
`

const PromocjePage: React.FC<PageProps<Queries.PromotionsPageQuery>> = ({
  data,
}) => {
  const PAGE_SEO = data?.wpPage?.seo
  if (!data.wpPage?.stronaGlowna || !PAGE_SEO)
    throw new Error("CMS data didn't load properly")

  const PROMOTIONS_IMAGES =
    React.useMemo(
      () =>
        data?.wpPage?.stronaGlowna?.promotionsPromotion?.map(
          (promotionItem) => ({
            src: promotionItem?.promotionsPromotionImg?.localFile
              ?.childImageSharp?.gatsbyImageData!,
            alt: promotionItem?.promotionsPromotionImg?.altText!,
            slideInnerLabel: '',
            label: promotionItem?.promotionsPromotionShopName!,
            link: promotionItem?.promotionsPromotionLink!,
            additionalLabel: promotionItem?.promotionsPromotionCommunication!,
          })
        ),
      []
    ) ?? []

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <PageMain>
        <TilesWrapper>
          {PROMOTIONS_IMAGES.map(
            (
              { src, alt, slideInnerLabel, label, link, additionalLabel },
              index
            ) => (
              <TileWrapper as="a" href={link} key={`tile-${index}`}>
                <ImgWrapper>
                  <LazyImage src={src} alt={alt} objectFit="contain" />
                  <InnerLabel
                    size={28}
                    weight={700}
                    themecolor="white"
                    dangerouslySetInnerHTML={{ __html: slideInnerLabel }}
                  />
                </ImgWrapper>
                <SlideInfo>
                  <Text
                    size={14}
                    weight={500}
                    themecolor="primary"
                    margin="10px"
                    dangerouslySetInnerHTML={{ __html: label }}
                  />
                  {additionalLabel && (
                    <Text
                      size={16}
                      weight={500}
                      themecolor="danger100"
                      transform="uppercase"
                      align="center"
                      dangerouslySetInnerHTML={{ __html: 'promocja' }}
                    />
                  )}
                </SlideInfo>
              </TileWrapper>
            )
          )}
        </TilesWrapper>
      </PageMain>
    </Layout>
  )
}

export default PromocjePage

export const query = graphql`
  query PromotionsPage {
    wpPage {
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          sourceUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
      }
      stronaGlowna {
        promotionsPromotion {
          promotionsPromotionShopName
          promotionsPromotionCommunication
          promotionsPromotionLink
          promotionsPromotionImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
